import { Component, OnInit } from '@angular/core';
import { GalleryService } from 'src/app/gallery/gallery.service';
import { Image } from 'src/app/gallery/image';

@Component({
  selector: 'app-about-brief',
  templateUrl: './about-brief.component.html',
  styleUrls: ['./about-brief.component.css']
})
export class AboutBriefComponent implements OnInit {
  aboutImage: Image;

  constructor(private galleryService: GalleryService) {
    
   }

  ngOnInit() {
    this.galleryService.getImageObject("3CuixBA8Ul8BFyJIA4TA").subscribe(data => this.aboutImage = data);
  }

}
