import { AuthGaurdService } from './auth-gaurd.service';
import { AuthService } from './auth.service';
import { BlogService } from './blog/blog.service';
import { GalleryService } from './gallery/gallery.service';
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { GalleryBriefComponent } from "./gallery/gallery-brief/gallery-brief.component";
import { GalleryFullComponent } from "./gallery/gallery-full/gallery-full.component";
import { AboutBriefComponent } from "./about/about-brief/about-brief.component";
import { AboutFullComponent } from "./about/about-full/about-full.component";
import { AdminLoginComponent } from "./admin/admin-login/admin-login.component";
import { AdminEditComponent } from "./admin/admin-edit/admin-edit.component";
import { BlogListComponent } from "./blog/blog-list/blog-list.component";
import { BlogDetailComponent } from "./blog/blog-detail/blog-detail.component";
import { BlogArchiveComponent } from "./blog/blog-archive/blog-archive.component";
import { NavbarComponent } from "./shared/navbar/navbar.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { HomeComponent } from "./home/home.component";
import { MDBBootstrapModule } from "angular-bootstrap-md";
import { LightboxModule } from 'ngx-lightbox';
import { RouterModule, Routes } from '@angular/router';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatGridListModule} from '@angular/material/grid-list';
import { GalleryPopupComponent } from './gallery/gallery-popup/gallery-popup.component';
import {MatCardModule} from '@angular/material/card';
import { FormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DropzoneDirective } from './dropzone.directive';
import { UploaderComponent } from './uploader/uploader.component';
import { UploadTaskComponent } from './upload-task/upload-task.component';
import { AboutComponent } from './about/about.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SanitizeHtmlPipe } from './santitize-html.pipe';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';


const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'blogs', component: BlogListComponent },
  { path: 'blogs/:id', component: BlogDetailComponent },
  { path: 'archive', component: BlogArchiveComponent },
  { path: 'gallery', component: GalleryFullComponent },
  { path: 'admin', component: AdminLoginComponent },
  { path: 'admin/edit', component: AdminEditComponent, canActivate: [AuthGaurdService] },
  { path: '**', redirectTo: '', pathMatch: 'full' }
]

@NgModule({
  declarations: [
    AppComponent,
    GalleryBriefComponent,
    GalleryFullComponent,
    AboutBriefComponent,
    AboutFullComponent,
    AdminLoginComponent,
    AdminEditComponent,
    BlogListComponent,
    BlogDetailComponent,
    BlogArchiveComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    GalleryPopupComponent,
    DropzoneDirective,
    UploaderComponent,
    UploadTaskComponent,
    AboutComponent,
    SanitizeHtmlPipe,
  ],
  imports: [
    BrowserModule,
    MDBBootstrapModule.forRoot(),
    RouterModule.forRoot(routes),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    LightboxModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    FormsModule,
    EditorModule,
    MatProgressBarModule,
    FontAwesomeModule,
    MatFormFieldModule,
    MatInputModule
  ],
  providers: [GalleryService, BlogService, AuthService, AuthGaurdService],
  bootstrap: [AppComponent]
})
export class AppModule { }
