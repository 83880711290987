import { AngularFirestoreDocument } from '@angular/fire/firestore';
import { Component, OnInit, Input } from '@angular/core';
import { GalleryService } from '../gallery.service';
import { Observable } from 'rxjs';
import { Image } from '../image';

@Component({
  selector: 'app-gallery-brief',
  templateUrl: './gallery-brief.component.html',
  styleUrls: ['./gallery-brief.component.css']
})
export class GalleryBriefComponent implements OnInit {
  images: Observable<Image[]>;
  imageHero: any;
  cols: number;
  @Input() count;

  constructor(private galleryService: GalleryService) { 
  }
  
  ngOnInit() {
    //this.images = this.galleryService.getImagesCount(4)
    this.galleryService.getImageObject("dbEgbM1jgdYJvBgEM5gW").subscribe(data => this.imageHero = data);
    if (window.innerWidth < 720) {
      this.cols = 1;
    } else if (window.innerWidth < 1300){
      this.cols = 2;
    } else {
      this.cols = 4;
    }
  }

  onResize(event) {
    if (window.innerWidth < 720) {
      this.cols = 1;
    } else if (window.innerWidth < 1300){
      this.cols = 2;
    } else {
      this.cols = 4;
    }
  }

  getUrl() {
    return this.imageHero.url;
  }
}
