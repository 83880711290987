import { GalleryService } from './../../gallery/gallery.service';
import { BlogService } from './../blog.service';
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Blog } from '../blog';
import { Image } from '../../gallery/image';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.css']
})
export class BlogListComponent implements OnInit {
  blogs: Blog[];
  images: Image[];
  maxLength: number =  300;
  @Input() count;

  constructor(private blogService: BlogService, private galleryService: GalleryService) { 
    blogService.getBlogs().subscribe(data => {
      this.blogs = data;
      if (this.count) {
        this.blogs = this.blogs.slice(0, this.count);
      }
      this.blogs.forEach(blog => {
        if (blog.imageId) {
          this.getImage(blog.imageId).subscribe(data => blog.image = data);
        }
      })
      this.blogs.forEach(blog => {
        if (blog.content.length > this.maxLength) {
          blog.content = blog.content.slice(0, this.maxLength) + "...";
          blog.blogLong = true;
        }
      })
    })
      
  }
  // .subscribe(data => {
  //   this.blogs = data;
  //   if (this.count) {
  //     this.blogs = this.blogs.slice(0,this.count);
  //   }
  // });
  ngOnInit() {
  }

  getImage(id) {
    return this.galleryService.getImage(id).valueChanges();
  }

}
