import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { AngularFireUploadTask } from '@angular/fire/storage/task';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/storage/';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.css']
})
export class UploadTaskComponent implements OnInit {
  @Input() file: File;
  task: AngularFireUploadTask;
  fileRef: AngularFireStorageReference;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL;

  constructor(private storage: AngularFireStorage, private db: AngularFirestore) { }

  ngOnInit(): void {
    this.startUpload();
  }

  startUpload() {
    const path = `images/${this.file.name}_${Date.now()}`;
    const ref = this.storage.ref(path);
    this.fileRef = ref;
    this.task = this.storage.upload(path, this.file);
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges().pipe(
      finalize(async() => {
        this.downloadURL = await ref.getDownloadURL().toPromise();

        this.db.collection('images').add({url: this.downloadURL, uploadDate: Date.now(), path});
      })
    )
  }

  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

  deleteUploadedPhoto() {
    this.fileRef.delete();
    this.percentage = null;
    this.snapshot = null;
  }

}
