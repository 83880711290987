import { Component, OnInit, Inject } from '@angular/core';
import { Image } from '../image';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-gallery-popup',
  templateUrl: './gallery-popup.component.html',
  styleUrls: ['./gallery-popup.component.css']
})
export class GalleryPopupComponent {

  constructor(
    public dialogRef: MatDialogRef<GalleryPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string) {
      console.log("popup", data);
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

}


