import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Blog } from './blog';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  blogCollection: AngularFirestoreCollection<Blog>;
  blog: AngularFirestoreDocument<Blog>;

  constructor(private afs: AngularFirestore) { 
    this.blogCollection = this.afs.collection<Blog>('blogs', ref =>
      ref.orderBy('publishDate', 'desc'));
  }

  getBlogs(): Observable<Blog[]> {
    return this.blogCollection.snapshotChanges().pipe(
      map(actions => {
      return actions.map(a => {
        const data = a.payload.doc.data() as Blog;
        const id = a.payload.doc.id;
        return { id, ...data };
      })
    })
  )}

  getBlogData(id: string) {
    this.blog = this.afs.doc<Blog>(`blogs/${id}`);
    return this.blog.valueChanges();
  }

  create(data: Blog) {
    return this.blogCollection.add(data);
  }

  getBlog(id: string) {
    return this.afs.doc<Blog>(`blogs/${id}`)
  }

  delete(id: string) {
    return this.getBlog(id).delete();
  }

  update(id: string, formData) {
    return this.getBlog(id).update(formData);
  }
}
