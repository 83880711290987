import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-archive',
  templateUrl: './blog-archive.component.html',
  styleUrls: ['./blog-archive.component.css']
})
export class BlogArchiveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
