import { GalleryPopupComponent } from './../gallery-popup/gallery-popup.component';
import { AuthService } from './../../auth.service';
import { BlogService } from './../../blog/blog.service';
import { GalleryService } from './../gallery.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Blog } from 'src/app/blog/blog';
import { take, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-gallery-full',
  templateUrl: './gallery-full.component.html',
  styleUrls: ['./gallery-full.component.css']
})
export class GalleryFullComponent implements OnInit {
  images = []   //Observable<Image[]>;
  slideIndex = 0;
  cols: number;
  blogs: Blog[] = [];
  blogImageIdString = "";

  constructor(private galleryService: GalleryService, public dialog: MatDialog, private blogService: BlogService, public authService: AuthService){
    this.blogService.getBlogs().pipe(take(1)).toPromise().then(b => {
      this.blogs = b;
      for(let blog of this.blogs) {
        this.blogImageIdString += (blog.imageId || "") + ",";
      }
    });
    galleryService.getImages().subscribe(data => {
      this.images = data;
    })
  }
  
  ngOnInit() {

    if (window.innerWidth < 720) {
      this.cols = 1;
    } else if (window.innerWidth < 1300){
      this.cols = 2;
    } else {
      this.cols = 4;
    }
  }

  onResize(event) {
    if (window.innerWidth < 720) {
      this.cols = 1;
    } else if (window.innerWidth < 1300){
      this.cols = 2;
    } else {
      this.cols = 4;
    }
  }

  deletePhoto(id) {
    if (confirm("Are you sure you want to delete this photo?")) {
      this.galleryService.delete(id);
    }
  }

  openDialog(img): void {
    const dialogRef = this.dialog.open(GalleryPopupComponent, {
      width:'500px',
      data: img.caption
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("result", result);
      if (result.confirmed) {
        img.caption = result.caption;
        this.galleryService.update(img.id, img)
      }
    });
  }
}
