import { GalleryService } from './../../gallery/gallery.service';
import { BlogService } from './../blog.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Blog } from '../blog';
import { Image } from 'src/app/gallery/image';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css']
})
export class BlogDetailComponent implements OnInit {
  blog: Blog;
  image: Image = null;
  url:string;
  caption: string;
  editing: Boolean = false;
  downloadURL;
  newPhoto: Boolean = false;

  constructor(private blogService: BlogService, 
              private route: ActivatedRoute, 
              private galleryService: GalleryService,
              private storage: AngularFireStorage,
              public authService: AuthService) { }

  ngOnInit() {
    this.getPost();
    console.log(this.authService.authState);
  }

  getPost() {
    const id = this.route.snapshot.paramMap.get('id');
    return this.blogService.getBlogData(id).subscribe(data => {
      this.blog = data;
      if (this.blog.imageId) {
        this.getImage(this.blog.imageId);
      }
    });
  }

  getImage(id) {
    this.galleryService.getImage(id).valueChanges().subscribe(data => {
      this.image = data
      this.caption = this.image.caption
    });
  }

  updateBlog() {
    const id = this.route.snapshot.paramMap.get('id');
    console.log(this.newPhoto);
    if (this.newPhoto) {
      this.galleryService.create({
        caption: this.caption,
        url: this.url,
        uploadDate: new Date()
      }).then(data => {
        this.blog.imageId = data.id;
      }).finally(() => {
        this.blogService.update(id, this.blog);
      })
    } else {
      this.blogService.update(id, this.blog);
      if (this.blog.imageId) {
        this.galleryService.update(this.blog.imageId, this.image);
      }
    }
    
    this.editing=false;
  }

  uploadImage(event){
    if (!this.image) {
      this.newPhoto = true;
    }
    const file = event.target.files[0];
    const path = `images/${file.name}_${Date.now()}`;
    const fileRef = this.storage.ref(path);
    if(file.type.split('/')[0] !== 'image') {
      return alert('Only image files are allowed.');
    } else {
      const task = this.storage.upload(path, file);
      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              this.url = url;
              if (this.image) {
                this.image.url = url;
              }
            }
          })
        })
      )
      .subscribe(url => console.log('final', url))
    }
  }
}
