import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Image } from './image';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  imageCollection: AngularFirestoreCollection<Image>;
  image: AngularFirestoreDocument<Image>;
  imageCountCollection: AngularFirestoreCollection<Image>;

  constructor(private afs: AngularFirestore) { 
    this.imageCollection = this.afs.collection<Image>('images', ref => 
      ref.orderBy('uploadDate', 'desc')
    );
  }

  getImages(): Observable<Image[]> {
    return this.imageCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Image;
          const id = a.payload.doc.id;
          return {id, ...data }
        })
      })
    )
  }

  getImagesCount(count: number) {
    this.imageCountCollection = this.afs.collection<Image>('images', ref => 
      ref.orderBy('uploadDate', 'desc').limit(count)
    )
    return this.imageCountCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Image;
          return { ...data }
        })
      })
    )
  }

  getStorageImages() {
    return this.afs.collection('images');
  }

  // a post should hold the id data for an image as posts are the only page where getting a single image makes sense
  getImage(id: string){
    return this.afs.doc<Image>(`images/${id}`);
  }

  getImageObject(id: string) {
    return this.afs.doc<Image>(`images/${id}`).snapshotChanges().pipe(
      map(actions => {
          const data = actions.payload.data() as Image;
          return { ...data }
        })
    );
  }

  create(data: Image) {
    return this.imageCollection.add(data);
  }

  delete(id: string) {
    return this.getImage(id).delete();
  }

  update(id: string, formData) {
    return this.getImage(id).update(formData);
  }
}
