import { Router } from '@angular/router';
import { GalleryService } from './../../gallery/gallery.service';
import { Observable } from 'rxjs';
import { BlogService } from './../../blog/blog.service';
import { AuthService } from './../../auth.service';
import { Component, OnInit } from '@angular/core';
import { ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/storage/';
import { finalize } from 'rxjs/operators';
import { Blog } from 'src/app/blog/blog';
import { Image } from 'src/app/gallery/image';

@Component({
  selector: 'app-admin-edit',
  templateUrl: './admin-edit.component.html',
  styleUrls: ['./admin-edit.component.css'],
  providers:[ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class AdminEditComponent implements OnInit {
  blogContent: any;
  title: string;
  image: string = null;
  editing: Boolean = false;
  editingGallery: Boolean = false;
  blogPhotoAdded: Boolean = false;
  fileRef: AngularFireStorageReference = null;
  imageId: string = null;
  caption: string = null;

  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  constructor(private authService: AuthService, 
    private blogService: BlogService, 
    private storage: AngularFireStorage, 
    private galleryService: GalleryService,
    private router: Router) { }

  ngOnInit() {
  }

  startBlog() {
    this.editing = true;
  }

  submitBlog() {
    if (this.fileRef) {
      this.galleryService.create({
        caption: this.caption,
        url: this.image,
        uploadDate: Date.now()
      }).then(data => {
        this.imageId = data.id;
        const blog: Blog = {
          title: this.title,
          content: this.blogContent,
          author: this.authService.authState.displayName || this.authService.authState.email,
          imageId: this.imageId,
          publishDate: new Date()
        };
        this.blogService.create(blog).then(data => {
          const blogId = data.id;
          this.router.navigate([`/blogs/${blogId}`]);
        });
      })
    } else {
      const blog: Blog = {
        title: this.title,
        content: this.blogContent,
        author: this.authService.authState.displayName || this.authService.authState.email,
        publishDate: new Date()
      };
      this.blogService.create(blog).then(data => {
        const blogId = data.id;
        this.router.navigate([`/blogs/${blogId}`]);
      });
    }
  }

  cancel() {
    this.editing = false;
    this.editingGallery = false;
    if (this.fileRef) {
      this.deletePhoto();
      this.fileRef = null;
      this.image = null;
    }
    this.blogPhotoAdded = false;
  }

  addPhotos() {
    this.editingGallery = true;
  }

  deletePhoto() {
    this.fileRef.delete();
  }

  uploadImage(event){
    if (this.fileRef) {
      this.deletePhoto();
    }
    const file = event.target.files[0];
    const path = `images/${file.name}_${Date.now()}`;
    const fileRef = this.storage.ref(path);
    this.fileRef = fileRef;
    if(file.type.split('/')[0] !== 'image') {
      return alert('Only image files are allowed.');
    } else {
      const task = this.storage.upload(path, file);
      this.uploadPercent = task.percentageChanges();
      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              this.image = url;
              this.blogPhotoAdded = true;
              this.uploadPercent = null;
            }
          })
        })
      )
      .subscribe(url => console.log('final', url))
    }
  }
}
