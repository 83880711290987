import { Router } from '@angular/router';
import { AuthService } from './../../auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {
  username: string = '';
  password: string = '';
  failedLogin: Boolean = false;
  errorMessage: string = '';
  loginAuthState;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    if (this.authService.authenticated) {
      this.router.navigate(["/admin/edit"])
    }
    console.log(this.authService.authState);
  }

  login() {
    this.authService.login(this.username, this.password).then(res => {
      this.authService.afAuth.authState.subscribe(data => {
        this.router.navigate(["/admin/edit"])
      })
      if (this.authService.authState) {

        
      }
      console.log("login", this.authService.authState);
    }, err => {
      this.failedLogin = true;
      this.errorMessage = err;
    })
  }

}
